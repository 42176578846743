.space-background {
    height: auto;
    width: 100%;
}

.space-background-container {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
}

.space-background-container:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
